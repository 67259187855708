<div class="top-bar">
  <!-- <div id="google_translate_element"></div> -->
  <div class="top-bar_block" *ngIf="!isMobile">
    <img
      class="logo"
      src="../../../assets/logo.svg"
      alt="logo"
      (click)="navigate()"
    />
    <div class="top-bar_block__links">
      <a (click)="navigate()" class="link" id="home-link" #homeLink>Home</a>
      <a (click)="navigate('trade')" class="link" id="trade-link" #tradeLink
        >Trading Apps</a
      >
      <a
        (click)="navigate('copytrading')"
        id="copy-trading-link"
        class="link"
        #copyTradingLink
        >Copy Trading</a
      >
      <a
        (click)="toDashboardPage()"
        class="link"
        id="dashboard-link"
        #dashboardLink
        >Dashboard</a
      >
    </div>
  </div>
  <div class="top-bar_block" *ngIf="!isMobile">
    <div class="membership-btn_background">
      <button class="membership-btn" (click)="navigate('membership')">
        Membership
      </button>
    </div>

    <button
      *ngIf="!user"
      class="login-btn marine-btn"
      (click)="signInWithDiscord()"
    >
      Login
    </button>
    <button *ngIf="user" class="login-btn marine-btn" (click)="logout()">
      Logout
    </button>
    <div
      *ngIf="user"
      class="top-bar_block__profile pointer"
      (click)="profileService.openProfilePage()"
    >
      <span class="top-bar_block__profile-avatar" *ngIf="user">{{
        user.data.discordUserName.split("").slice(0, 2).join("") | uppercase
      }}</span>
    </div>
  </div>
</div>
