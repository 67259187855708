import { EnvironmentModel } from 'src/app/core/models/environment.model';

export const environment: EnvironmentModel = {
  production: false,
  apiBaseUrl: 'https://crypto-traders-core-prod-api.azurewebsites.net/api/v1/',
  discordClientId: '1152148120709374032',
  IntercomAppId: 'kz9zp2wu',
  payBotEliteUrl: 'https://pay.cryptotraders.com/b/test_8wM3ed5Dc9kR5xubIK',
  payBotElitePlusUrl: 'https://pay.cryptotraders.com/b/test_14k1650iSbsZ9NKfYZ',
  googleTagManagerId: 'GTM-W4XQ594S',
  xtradesApp: 'https://app.alpha.xtrades.net/',
  staffRole: '1232381873494429769',
  //discordClientId: '1152148120709374032', //Xtrades
};
