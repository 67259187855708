import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../core/services/auth.service';
import { UserModel } from '../../../core/models/user/user';
import { AppQuery } from 'src/app/core/state/app/app.query';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ScreenService } from 'src/app/core/services/screen.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  public user: UserModel;
  public isMobile: boolean;
  @Input() hideLogin = false;
  @Input() activeLink = null;
  @Output() isLoggedUser = new EventEmitter();
  @ViewChild('confirmOnboarding', { static: false })
  confirmOnboarding: TemplateRef<any>;
  subscription: Subscription = new Subscription();
  @ViewChild('homeLink') homeLink: ElementRef;
  @ViewChild('tradeLink') tradeLink: ElementRef;
  @ViewChild('copyTradingLink') copyTradingLink: ElementRef;
  @ViewChild('dashboardLink') dashboardLink: ElementRef;
  plan: any;
  activePlan: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private appQuery: AppQuery,
    public profileService: ProfileService,
    private screenService: ScreenService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.screenService.isMobileView;
    this.subscription.add(
      this.screenService.$isMobileSubject.subscribe((data: boolean) => {
        this.isMobile = data;
      })
    );
    this.subscription.add(
      this.appQuery
        .select((x) => x.currentUser)
        .subscribe((user) => {
          this.user = user;
        })
    );
  }

  ngAfterViewInit(): void {
    const navLinks = [
      this.homeLink,
      this.tradeLink,
      this.copyTradingLink,
      this.dashboardLink,
    ];
    navLinks.forEach((link) => {
      if (link?.nativeElement?.id === this.activeLink) {
        link.nativeElement.classList.add('active-link');
      }
    });
  }

  navigate(url: string = ''): void {
    this.router.navigate([`/${url}`]);
  }

  async signInWithDiscord(): Promise<void> {
    try {
      await this.authService.signInDiscord();
    } catch (err) {
      console.log(err);
    }
  }

  public logout(): void {
    this.authService.logout();
    if (location.href.includes('dashboard')) {
      this.router.navigate(['/home']);
    } else {
      window.location.reload();
    }
  }

  public toDashboardPage(): void {
    if (this.user) {
      this.router.navigate(['/dashboard']);
    } else {
      this.signInWithDiscord();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
