import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  SubscriptionInterval,
  SubscriptionIntervalLabel,
} from 'src/app/core/enums/subscription-interval';
import { DetailsPlanModel, PlanModel } from 'src/app/core/models/plans/plans';
import { Plan } from 'src/app/core/models/plans/plans-subscription-types';
import { PlansService } from 'src/app/core/services/plans.service';

@Component({
  selector: 'app-buy-subscription',
  templateUrl: './buy-subscription.component.html',
  styleUrls: ['./buy-subscription.component.scss'],
})
export class BuySubscriptionComponent implements OnInit {
  @Input() plan: PlanModel;
  @Input() tab: DetailsPlanModel;
  @Input() withAllPeriods: boolean;
  public planSavings: number;
  public subscriptionInterval = SubscriptionInterval;
  public planFullPrice;
  public periodSwitcher = {
    oneMonth: '1M',
    sixMonths: '6M',
    oneYear: '1Y',
    selectedPeriod: '',
  };
  public planTitle = Plan;

  constructor(
    private modalController: ModalController,
    private plansService: PlansService
  ) {}

  ngOnInit(): void {
    if (this.withAllPeriods) {
      this.periodSwitcher.selectedPeriod = this.periodSwitcher.oneMonth;
      this.switchPeriod(this.periodSwitcher.selectedPeriod);
    } else {
      this.getPlanFullPrice();
      this.getPlanSavings();
    }
  }

  switchPeriod(data: string): void {
    switch (data) {
      case this.periodSwitcher.oneMonth:
        this.tab = this.plan.details.find(
          (el) =>
            el.subscriptionIntervalCount === SubscriptionInterval.Month &&
            el.subscriptionInterval === SubscriptionIntervalLabel.month
        );
        break;
      case this.periodSwitcher.sixMonths:
        this.tab = this.plan.details.find(
          (el) =>
            el.subscriptionIntervalCount === SubscriptionInterval.Month * 6 &&
            el.subscriptionInterval === SubscriptionIntervalLabel.month
        );
        break;
      case this.periodSwitcher.oneYear:
        this.tab = this.plan.details.find(
          (el) =>
            el.subscriptionIntervalCount === SubscriptionInterval.Month &&
            el.subscriptionInterval === SubscriptionIntervalLabel.year
        );
        break;
      default:
        break;
    }
    this.getPlanFullPrice();
    this.getPlanSavings();
  }

  public close(checkoutPlan = null): void {
    this.modalController.dismiss(checkoutPlan);
  }

  private getPlanFullPrice() {
    const plan = this.plansService.plans.find(
      (elem) => elem.title === this.plan.title
    );
    this.planFullPrice =
      plan?.details.find((el) => {
        return (
          el.subscriptionInterval === this.tab.subscriptionInterval &&
          el.subscriptionIntervalCount === this.tab.subscriptionIntervalCount
        );
      })?.userPrice || this.tab.userPrice;
  }

  private getPlanSavings() {
    const monthCount = { Month: 1, Year: 12 };
    const monthlyFullPlanPrice = this.plan.details[0].price;
    const fullPricePerPeriod =
      monthlyFullPlanPrice *
      monthCount[this.tab.subscriptionInterval] *
      +this.tab.subscriptionIntervalCount;
    const fullPriceWithSavings =
      this.tab.price *
      monthCount[this.tab.subscriptionInterval] *
      +this.tab.subscriptionIntervalCount;
    this.planSavings =
      100 - Math.trunc((fullPriceWithSavings / fullPricePerPeriod) * 100);
  }
}
